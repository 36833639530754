import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  format,
  addDays,
  differenceInCalendarDays,
  isBefore,
  isValid,
  isFuture,
} from 'date-fns';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  CheckboxInput,
  handleNumbers,
  getDateInputValue,
  RadioInput,
  minDate,
  validateDate,
  composeValidators,
  minReasonLength,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { authGet } from '../../../../server';
import { weekDays, constants } from '../../../../utils';
import { useGetCaseId } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
import { SaveButton } from '../../../../design';
const {
  updateTypes,
  caseTypes,
  pendingChances,
  authorization_type,
  therapyServices,
  levels,
} = constants;

const isPrimary = [
  { id: true, name: 'Primary' },
  { id: false, name: 'Secondary' },
];
const UPDATES_ONLY_ID = 16;
const AUTH_MISSING_ID = 6;
const AUTH_ON_HOLD_ID = 8;
const AUTH_UPDATES_STATUS_PENDING = 5;
const PART_B_AUTH_ID = 18;
const DENIAL_RECEIVED = 3;
const DENIED = 4;

export const AuthorizationForm = ({
  isEdit,
  caseId,
  record,
  handleClose,
  refresh,
  authorizationId,
  meta,
}) => {
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const roles = localStorage.getItem('roles');
  const isPartB = meta.part_b;
  const [formData, setFormData] = useState({
    case_id: caseId,
    authorization_status_id: !isEdit ? 1 : undefined,
    update_type: [],
    update_weekday: [],
    schedule_type: '',
    is_primary: isEdit && record.is_retro ? record.is_primary : undefined,
    request_continued_auth: null,
    level_change: 'true',
  });

  const [pt_option, setPtOption] = useState('units');
  const [ot_option, setOtOption] = useState('units');
  const [speech_option, setSpeechOption] = useState('units');
  const [submit, setSubmit] = useState(false);
  const [subLevelChoices, setSubLevelChoices] = useState([]);
  useEffect(() => {
    if (isEdit && record && !authorizationId) {
      populateForm(record, setFormData, isEdit, roles, coveredUserId, userId);
      getSubLevelChoices(
        setSubLevelChoices,
        record.cases_payers_id,
        record.authorization_type_id,
        record.start_date,
      );
    } else {
      setFormData(f => ({
        ...f,
        user_id: roles?.includes('case_manager')
          ? coveredUserId || userId
          : undefined,
      }));
    }
  }, [isEdit, record, userId, authorizationId, coveredUserId, roles]);

  useEffect(() => {
    async function fetchAuth() {
      const data = await fetchResource(`/authorizations/${authorizationId}`);
      if (data !== 'error') {
        populateForm(data, setFormData, isEdit);
      }
    }
    if (authorizationId) {
      fetchAuth();
    }
  }, [authorizationId, isEdit]);
  const updateTasksCount = useCallback(() => {
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }, [caseIdFromUrl, dispatch]);
  const onSubmit = value => {
    setSubmit(true);
    const { update_frequency: _update_frequency } = value;
    const update_frequency =
      _update_frequency !== 'freq_days' ? _update_frequency : null;
    const outpatient_services = getOutpatientServicesValues(value);
    if (outpatient_services === 'error') return Promise.resolve();
    const higherLevelRequested = value.higher_level_requested
      ? value.higher_level_requested === 'true'
      : undefined;
    if (isEdit) {
      return dataProvider
        .update('authorizations', {
          id: record.id || value.authorization_id,
          data: {
            ...value,
            update_frequency,
            schedule_type: undefined,
            outpatient_services,
            higher_level_requested: higherLevelRequested,
            inactive_schedule: false,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          setSubmit(false);
          handleClose();
          updateTasksCount();
          typeof refresh === 'function' && refresh();
          dispatch(uiActions.getSidebarCases());
        })
        .catch(
          error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          setSubmit(false),
        );
    } else {
      return dataProvider
        .create('authorizations', {
          data: {
            ...value,
            update_frequency,
            schedule_type: undefined,
            outpatient_services,
            higher_level_requested: higherLevelRequested,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          setSubmit(false);
          handleClose();
          updateTasksCount();
          typeof refresh === 'function' && refresh();
          dispatch(uiActions.getSidebarCases());
        })
        .catch(
          error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          setSubmit(false),
        );
    }
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    return authOnChange({
      eventOrValue,
      _name,
      type,
      isEdit,
      setFormData,
      formData,
      roles,
      coveredUserId,
      userId,
      setSubLevelChoices,
    });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all' },
                      }}
                    />
                  </div>
                )}

                {values.case_id >= 0 && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <ReferenceInput
                      key={values.case_id}
                      reference='cases-payers/list'
                      customOnChange={customOnChange}
                      name='cases_payers_id'
                      label='Payer'
                      validate={required()}
                      required
                      fullWidth
                      options={{
                        filter: { case_id: values.case_id },
                      }}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                <CheckboxInput
                  name='is_retro'
                  customOnChange={customOnChange}
                  label='Retro authorization'
                  checked={values.is_retro}
                />
                {formData.is_retro && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Payer status'
                      validate={required()}
                      required
                      name='is_primary'
                      choices={isPrimary}
                    />
                    <FormTextField
                      name='retro_close_date'
                      customOnChange={customOnChange}
                      type='date'
                      label='Retro close date'
                    />
                  </div>
                )}
                <AuthFields
                  isEdit={isEdit}
                  customOnChange={customOnChange}
                  caseId={caseId}
                  values={values}
                  record={record}
                  formData={formData}
                  setFormData={setFormData}
                  authorizationId={authorizationId}
                  ot_option={ot_option}
                  pt_option={pt_option}
                  speech_option={speech_option}
                  setOtOption={setOtOption}
                  setPtOption={setPtOption}
                  setSpeechOption={setSpeechOption}
                  roles={roles}
                  subLevelChoices={subLevelChoices}
                  isPartB={isPartB}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting || submit}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

export const AuthFields = ({
  isEdit,
  customOnChange,
  caseId,
  values,
  record,
  formData,
  setFormData,
  authorizationId,
  pt_option,
  ot_option,
  speech_option,
  setPtOption,
  setOtOption,
  setSpeechOption,
  roles,
  subLevelChoices,
  isPartB,
  isInNetwork,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={clsx(classes.inputContainerWrap)}>
        {values.case_id >= 0 && (
          <ReferenceInput
            reference='auth-number/list'
            customOnChange={customOnChange}
            name='authorization_id'
            label='Auth number'
            disabled={!!authorizationId && !!isEdit}
            style={{
              marginRight: values.authorization_id === 0 ? 8 : 0,
            }}
            resetOption={{ id: 0, name: 'Add new auth #' }}
            options={{
              filter: { case_id: values.case_id },
            }}
          />
        )}
        {values.authorization_id === 0 && (
          <FormTextField
            name='insurance_auth_number'
            validate={required()}
            required
            customOnChange={customOnChange}
            label='Auth number'
          />
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <ReferenceInput
          reference='facility-users/list'
          customOnChange={customOnChange}
          name='user_id'
          label='Case manager'
          validate={required()}
          perPage={500}
          required
          options={{
            filter: {
              is_active: !isEdit,
              case_manager: !!roles?.includes('case_manager'),
              all_roles: true,
              edit_user: isEdit ? record?.user_id : undefined,
            },
          }}
        />
        <ReferenceInput
          customOnChange={customOnChange}
          label='Auth status'
          validate={composeValidators([required(), validateAuthStatus])}
          required
          name='authorization_status_id'
          reference='auths-status/list'
          options={{
            filter: {
              create: !!!isEdit,
              current: record ? record.authorization_status_id : undefined,
            },
          }}
        />
      </div>
      {values.authorization_status_id === AUTH_UPDATES_STATUS_PENDING && (
        <Fragment>
          <SelectComponent
            customOnChange={customOnChange}
            label='Likelihood of approval'
            validate={required()}
            required
            name='approval_chance'
            choices={pendingChances}
            fullWidth
          />
          <FormTextField
            name='pending_reason'
            label='Pending reason'
            multiline
            fullWidth
            customOnChange={customOnChange}
            validate={composeValidators([required(), minReasonLength()])}
            required
          />
        </Fragment>
      )}
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='start_date'
          validate={composeValidators([required(), validateDate()])}
          required
          customOnChange={customOnChange}
          type='date'
          label='Start date'
        />

        <FormTextField
          name='days_approved'
          customOnChange={customOnChange}
          validate={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
              ? required()
              : undefined
          }
          required={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
          }
          disabled={
            values.authorization_status_id === DENIED ||
            values.authorization_status_id === DENIAL_RECEIVED
          }
          label='Days approved'
          type='number'
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='end_date'
          validate={getEndDateValidators(values)}
          min={values.start_date}
          required={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
          }
          customOnChange={customOnChange}
          type='date'
          label='Initial end date'
        />
        <FormTextField
          name='days_available'
          customOnChange={customOnChange}
          label='Days available'
          disabled
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {isEdit && (
          <Fragment>
            <FormTextField
              name='actual_end_date'
              customOnChange={customOnChange}
              type='date'
              label='Actual end date'
              min={values.start_date}
              validate={composeValidators([
                validateDate(),
                minDate(
                  values.start_date,
                  'End date cannot be before the start date',
                ),
              ])}
            />
            <div style={{ flex: '1 0 160px' }} />
          </Fragment>
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {!isPartB && (
          <SelectComponent
            customOnChange={customOnChange}
            label='Case type'
            validate={
              formData.authorization_type_id !== authorization_type.PHARMACY &&
              formData.authorization_type_id !== authorization_type.PART_B
                ? required()
                : undefined
            }
            required={
              formData.authorization_type_id !== authorization_type.PHARMACY &&
              formData.authorization_type_id !== authorization_type.PART_B
            }
            name='is_mltc'
            choices={caseTypes}
            disabled={
              formData.authorization_type_id === authorization_type.PHARMACY ||
              formData.authorization_type_id === authorization_type.PART_B
            }
          />
        )}
        <ReferenceInput
          key={[
            values.is_mltc,
            values.cases_payers_id,
            formData.line_of_business_id,
            formData.payer_id,
            formData.start_date,
            formData.in_network,
            isInNetwork,
          ]}
          reference='auth-types/list'
          options={{
            filter: {
              mltc: formData.is_mltc ? 1 : [],
              subacute: formData.is_mltc ? [] : 1,
              cases_payers_id: formData.cases_payers_id,
              line_of_business_id: formData.line_of_business_id,
              payer_id: formData.payer_id,
              start_date: formData.start_date,
              include_level: isEdit ? record.authorization_type_id : undefined,
              part_b: isPartB,
              in_network: formData.in_network || isInNetwork,
            },
          }}
          customOnChange={customOnChange}
          name='authorization_type_id'
          label='Rate type'
          validate={required()}
          required
          style={{
            marginRight: subLevelChoices.length ? 20 : isPartB ? 0 : undefined,
          }}
        />
        {subLevelChoices.length ? (
          <SelectComponent
            customOnChange={customOnChange}
            label='Sub-level'
            name='sub_level'
            choices={subLevelChoices}
            required
            style={{ marginRight: 0 }}
          />
        ) : null}
      </div>
      {isEdit &&
        formData.authorization_type_id !== record.authorization_type_id && (
          <div className={clsx(classes.inputContainerWrap)}>
            <RadioInput
              row
              className={classes.radio}
              customOnChange={customOnChange}
              name='level_change'
              value={formData.level_change}
              label='Reason for change'
              required
              validate={required()}
              radios={[
                {
                  label: 'Level Change',
                  value: 'true',
                  message:
                    'Actively requested a higher level or downgrade consciously made by insurance (ex. level 1 to level 2 due to rehab mins)',
                },
                {
                  label: 'Level Correction',
                  value: 'false',
                  message:
                    'Correction of reading error or level only given after initial clinicals reviewed (ex. pending to level 1; read auth letter wrong and correcting)',
                },
              ]}
            />
          </div>
        )}
      {formData.authorization_type_id ===
        authorization_type.SINGLE_CASE_AGREEMENT_RATE && (
        <FormTextField
          name='rate_details'
          label='Rate details'
          multiline
          fullWidth
          customOnChange={customOnChange}
          required
        />
      )}
      {formData.authorization_type_id === authorization_type.LEVEL_1 &&
        formData.authorization_status_id !== DENIED &&
        (!isEdit ||
          (isEdit && new Date(formData.start_date).getFullYear() >= 2022)) && (
          <div className={clsx(classes.inputContainerWrap)}>
            <ReferenceInput
              reference='level-one-reasons/list'
              customOnChange={customOnChange}
              name='level_one_reason_id'
              label='Reason for Level 1 auth'
              required
              validate={required()}
              fullWidth
              style={{ marginRight: 0 }}
            />
            {formData.level_one_reason_id === 2 && (
              <ReferenceInput
                key={formData.level_one_reason_id}
                reference='insufficient-criteria-reasons/list'
                customOnChange={customOnChange}
                name='insufficient_criteria_id'
                label='Insufficient criteria details'
                required
                validate={required()}
                fullWidth
              />
            )}
            {formData.level_one_reason_id === 3 && (
              <ReferenceInput
                key={formData.level_one_reason_id}
                reference='auth-types/list'
                customOnChange={customOnChange}
                name='qualifies_for_level'
                label='Qualifying level'
                required
                validate={required()}
                fullWidth
                options={{
                  filter: {
                    levels: true,
                  },
                }}
              />
            )}
            <FormTextField
              name='level_one_reason'
              label='Reason details'
              customOnChange={customOnChange}
              required={formData.level_one_reason_id === 4}
              validate={
                formData.level_one_reason_id === 4
                  ? composeValidators([required(), minReasonLength()])
                  : minReasonLength()
              }
              fullWidth
              multiline={formData.level_one_reason_id === 4}
              style={{ marginRight: 0 }}
            />
          </div>
        )}
      {isEdit &&
        formData.authorization_type_id === authorization_type.LEVEL_1 &&
        formData.authorization_status_id !== DENIED &&
        new Date(formData.start_date).getFullYear() >= 2022 && (
          <div>
            <RadioInput
              row
              className={classes.radio}
              customOnChange={customOnChange}
              name='higher_level_requested'
              value={formData.higher_level_requested}
              label='Reason for change'
              required
              validate={required()}
              radios={[
                {
                  label: 'Yes',
                  value: 'true',
                },
                {
                  label: 'No',
                  value: 'false',
                },
              ]}
            />
            {formData.higher_level_requested === 'false' && (
              <FormTextField
                name='no_request_reason'
                label='Reason higher level was not requested'
                customOnChange={customOnChange}
                required
                validate={composeValidators([required(), minReasonLength()])}
                fullWidth
              />
            )}
          </div>
        )}
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='letter_received'
          customOnChange={customOnChange}
          type='date'
          label='Auth letter received'
          validate={composeValidators([validateDate()])}
        />
        <ReferenceInput
          reference='auth-letter-status/list'
          customOnChange={customOnChange}
          name='auth_letter_status_id'
          label='Auth letter status'
        />
      </div>
      {values.case_id && (
        <div className={clsx(classes.inputContainerWrap)}>
          <ReferenceInput
            reference='auth-diagnoses/list'
            customOnChange={customOnChange}
            name='primary_diagnosis_value'
            label='Primary diagnosis'
            resetOption={{ id: 0, name: 'Add new diagnosis' }}
            options={{
              filter: {
                case_id: values.case_id,
              },
            }}
            style={{
              marginRight: values.primary_diagnosis_value === 0 ? 8 : 0,
            }}
          />
          {values.primary_diagnosis_value === 0 && (
            <FormTextField
              name='primary_diagnosis'
              customOnChange={customOnChange}
              label='Primary diagnosis'
            />
          )}
        </div>
      )}
      {values.case_id && (
        <div className={clsx(classes.inputContainerWrap)}>
          <ReferenceInput
            reference='auth-diagnoses/list'
            customOnChange={customOnChange}
            name='billing_diagnosis_value'
            label='Billing diagnosis'
            resetOption={{ id: 0, name: 'Add new diagnosis' }}
            options={{
              filter: {
                case_id: values.case_id,
              },
            }}
            style={{
              marginRight: values.billing_diagnosis_value === 0 ? 8 : 0,
            }}
          />
          {values.billing_diagnosis_value === 0 && (
            <FormTextField
              name='billing_diagnosis'
              customOnChange={customOnChange}
              label='Billing diagnosis'
            />
          )}
        </div>
      )}
      <FormTextField
        name='auth_notes'
        label='Notes'
        multiline
        fullWidth
        customOnChange={customOnChange}
        validate={minReasonLength()}
      />
      {(formData.authorization_type_id === authorization_type.PHARMACY ||
        formData.authorization_type_id === authorization_type.PART_B) && (
        <Fragment>
          {formData.authorization_type_id === authorization_type.PART_B && (
            <RadioInput
              row
              name='is_therapy'
              customOnChange={customOnChange}
              value={formData.is_therapy}
              label=''
              radios={[
                {
                  label: 'Therapy',
                  value: 'true',
                },
                {
                  label: 'Other Service',
                  value: 'false',
                },
              ]}
            />
          )}
          {formData.is_therapy === 'true' &&
          formData.authorization_type_id === authorization_type.PART_B ? (
            <FormGroup row>
              <CheckboxInput
                name='pt'
                customOnChange={customOnChange}
                label='PT'
                checked={values.pt}
              />
              <CheckboxInput
                name='ot'
                customOnChange={customOnChange}
                label='OT'
                checked={values.ot}
              />
              <CheckboxInput
                name='speech'
                customOnChange={customOnChange}
                label='Speech'
                checked={values.speech}
              />
            </FormGroup>
          ) : (
            (formData.is_therapy === 'false' ||
              formData.authorization_type_id ===
                authorization_type.PHARMACY) && (
              <Fragment>
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                  style={{ marginTop: -30 }}
                >
                  {`${
                    formData.authorization_type_id === authorization_type.PART_B
                      ? `Services`
                      : `Medications`
                  }`}
                </DialogContentText>
                <AutocompleteInput
                  key={formData.authorization_type_id}
                  className={classes.input}
                  trimLength={1}
                  customOnChange={customOnChange}
                  reference='services/list'
                  name='service_id'
                  label={`${
                    formData.authorization_type_id === authorization_type.PART_B
                      ? `Service`
                      : `Medication`
                  }`}
                  openOnFocus
                  autocompleteProps={{ openOnFocus: true }}
                  viewAll
                  inputStyle={{ marginLeft: 0, marginRight: 0 }}
                  fullWidth
                  initialSelectedItem={formData.service}
                  clearable
                  options={{
                    filter: {
                      ancillary: true,
                      part_b:
                        formData.authorization_type_id ===
                        authorization_type.PART_B,
                    },
                  }}
                />
              </Fragment>
            )
          )}
          {values.pt && (
            <Fragment>
              <p className={classes.sectionSubHeader}>PT requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle setState={setPtOption} curValue={pt_option} />
              </div>
              <FormTextField
                name='pt_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {pt_option === 'minutes' ? (
                <FormTextField
                  name='pt_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='pt_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
          {values.ot && (
            <Fragment>
              <p className={classes.sectionSubHeader}>OT requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle setState={setOtOption} curValue={ot_option} />
              </div>
              <FormTextField
                name='ot_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {ot_option === 'minutes' ? (
                <FormTextField
                  name='ot_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='ot_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
          {values.speech && (
            <Fragment>
              <p className={classes.sectionSubHeader}>Speech requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle
                  setState={setSpeechOption}
                  curValue={speech_option}
                />
              </div>
              <FormTextField
                name='speech_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {speech_option === 'minutes' ? (
                <FormTextField
                  name='speech_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='speech_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
          {formData.authorization_type_id === authorization_type.PHARMACY && (
            <Fragment>
              {' '}
              <div className={clsx(classes.inputContainerWrap)}>
                <SelectComponent
                  name='drug_route'
                  customOnChange={customOnChange}
                  label='Drug Route'
                  choices={drugRouteList}
                  fullWidth
                  style={{ marginRight: 0 }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='dosage'
                  customOnChange={customOnChange}
                  label='Dosage'
                />
                <FormTextField
                  name='strength'
                  customOnChange={customOnChange}
                  label='Strength'
                />
              </div>
            </Fragment>
          )}
          <div style={{ marginBottom: 10 }}>
            <RadioInput
              name='request_continued_auth'
              className={classes.radio}
              customOnChange={customOnChange}
              required
              validate={required()}
              value={formData.request_continued_auth}
              label='Request continued authorization'
              row
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'Unknown', value: 'unknown' },
              ]}
            />
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              name='process_date'
              customOnChange={customOnChange}
              type='date'
              label='Process date'
            />
            <SelectComponent
              customOnChange={customOnChange}
              label='Auth request type'
              formField={formData.update_type}
              name='auth_request_type'
              choices={[
                { id: 'Initial', name: 'Initial' },
                { id: 'Extension', name: 'Extension' },
              ]}
            />
          </div>
        </Fragment>
      )}
      <DialogContentText align='left' variant='h6' className={classes.header}>
        Updates
      </DialogContentText>
      <p className={classes.subHeader}>
        Set up a single or recurring updates schedule
      </p>
      <div>
        <Divider className={classes.divider} />
        <div style={{ marginBottom: 10 }}>
          <RadioInput
            key={values.authorization_status_id}
            name='schedule_type'
            className={classes.radio}
            customOnChange={customOnChange}
            value={formData.schedule_type}
            validate={
              ![AUTH_ON_HOLD_ID, DENIED].includes(
                values.authorization_status_id,
              ) &&
              ![
                authorization_type.PART_B,
                authorization_type.PHARMACY,
              ].includes(values.authorization_type_id) &&
              required()
            }
            required={
              ![AUTH_ON_HOLD_ID, DENIED].includes(
                values.authorization_status_id,
              ) &&
              ![
                authorization_type.PART_B,
                authorization_type.PHARMACY,
              ].includes(values.authorization_type_id)
            }
            label='Update schedule'
            radios={[
              {
                label: 'No update schedule',
                value: 'no_update',
              },

              { label: 'One-time update', value: 'one_time' },
              { label: 'Recurring by date', value: 'recurring_date' },
              {
                label: 'Recurring by day/days of the week ',
                value: 'recurring_day',
              },
            ]}
          />
        </div>
        <div className={clsx(classes.inputContainerWrap)}>
          {values.schedule_type === 'no_update' && (
            <FormTextField
              name='no_update_reason'
              label='No updates reason'
              multiline
              fullWidth
              customOnChange={customOnChange}
              validate={composeValidators([required(), minReasonLength()])}
              required
            />
          )}
        </div>
        <div className={clsx(classes.inputContainerWrap)}>
          {(values.schedule_type === 'one_time' ||
            values.schedule_type === 'recurring_date') && (
            <FormTextField
              name='initial_update_date'
              customOnChange={customOnChange}
              type='date'
              label='Select date'
              required={values.schedule_type === 'recurring_date'}
              validate={composeValidators([validateDate(), ...[required()]])}
            />
          )}
          {values.schedule_type === 'recurring_day' && (
            <SelectComponent
              customOnChange={customOnChange}
              label='Specific days of week'
              multiple
              renderWith='chip'
              formField={formData.update_type}
              name='update_weekday'
              choices={weekDays}
              validate={required()}
            />
          )}

          {values.schedule_type === 'recurring_date' ||
          values.schedule_type === 'recurring_day' ? (
            <SelectComponent
              customOnChange={customOnChange}
              label='Recurring frequency'
              name='update_frequency'
              choices={getRecurringChoices(
                values.schedule_type === 'recurring_date',
              )}
              validate={required()}
              required
              disabled={
                Array.isArray(values.update_weekday) &&
                values.update_weekday.length > 1
              }
              style={{ marginRight: 0 }}
            />
          ) : (
            <div style={{ flex: '1 0 160px' }} />
          )}
        </div>
        <div className={clsx(classes.inputContainerWrap)}>
          {values.update_frequency === 'freq_days' && (
            <FormTextField
              name='update_frequency_days'
              customOnChange={customOnChange}
              label='Frequency days'
              type='number'
              validate={required()}
              required
            />
          )}
        </div>
        {formData.schedule_type &&
          formData.schedule_type !== 'no_update' &&
          formData.schedule_type !== '' && (
            <SelectComponent
              customOnChange={customOnChange}
              label='Update type'
              multiple
              renderWith='chip'
              formField={formData.update_type}
              name='update_type'
              choices={updateTypes}
              fullWidth
              setFormData={setFormData}
              validate={required()}
            />
          )}
      </div>
      {formData.schedule_type &&
        formData.schedule_type !== 'no_update' &&
        formData.schedule_type !== '' && (
          <FormTextField
            name='update_notes'
            label='Update notes'
            multiline
            fullWidth
            customOnChange={customOnChange}
            validate={minReasonLength()}
          />
        )}
    </Fragment>
  );
};

export const authOnChange = async ({
  eventOrValue,
  _name,
  type,
  isEdit,
  setFormData,
  formData,
  roles,
  coveredUserId,
  userId,
  setSubLevelChoices,
}) => {
  // when using a react-admin input, onChange returns
  // the value instead of the target.
  let value = eventOrValue;
  let name = _name;
  if (typeof eventOrValue === 'object') {
    const target = eventOrValue.target;
    value = target.type === 'checkbox' ? target.checked : target.value;
    name = target.name;
  }
  if (type === 'number') {
    value = handleNumbers(value);
  }
  if (type === 'date') {
    value = getDateInputValue(value);
  }

  if (name === 'authorization_id') {
    if (!isEdit && +value !== 0) {
      const data = await fetchResource('/auth-data', {
        id: value,
      });
      if (data !== 'error') {
        const {
          user_id,
          authorization_type_id,
          is_mltc,
          update_weekday,
          update_type,
          update_frequency,
          update_frequency_days,
          line_of_business_id,
        } = data;

        setFormData(f => ({
          ...f,
          [name]: value,
          ...data,
          user_id: roles?.includes('case_manager')
            ? coveredUserId || userId
            : user_id,
          authorization_type_id,
          line_of_business_id,
          is_mltc,
          update_weekday: Array.isArray(update_weekday) ? update_weekday : [],
          update_type: Array.isArray(update_type) ? update_type : [],
          schedule_type: setScheduleType(data),
          update_frequency: update_frequency
            ? update_frequency
            : update_frequency_days
            ? 'freq_days'
            : undefined,
        }));
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    return;
  }

  if (name === 'authorization_type_id') {
    setSubLevelChoices([]);
    if (Object.values(levels).includes(value)) {
      getSubLevelChoices(
        setSubLevelChoices,
        formData.cases_payers_id,
        value,
        formData.start_date,
        formData.payer_id,
        formData.line_of_business_id,
      );
    }
    if (value === authorization_type.LEVEL_1 && !isEdit) {
      setFormData(f => ({
        ...f,
        [name]: value,
        level_one_reason_id: 1,
        level_one_reason: null,
        insufficient_criteria_id: null,
        qualifies_for_level: null,
        rate_details: null,
        sub_level: null,
        service_id: null,
        service: null,
        is_therapy: null,
        pt: null,
        ot: null,
        speech: null,
        pt_frequency: null,
        pt_minutes: null,
        pt_units: null,
        ot_frequency: null,
        ot_minutes: null,
        ot_units: null,
        speech_frequency: null,
        speech_minutes: null,
        speech_units: null,
        dosage: null,
        strength: null,
        drug_route: null,
        process_date: null,
        auth_request_type: null,
      }));
      return;
    } else if (
      value === authorization_type.PART_B ||
      value === authorization_type.PHARMACY
    ) {
      setFormData(f => ({
        ...f,
        [name]: value,
        level_one_reason_id: null,
        level_one_reason: null,
        insufficient_criteria_id: null,
        qualifies_for_level: null,
        rate_details: null,
        sub_level: null,
        service_id: null,
        service: null,
        is_therapy: null,
        pt: null,
        ot: null,
        speech: null,
        pt_frequency: null,
        pt_minutes: null,
        pt_units: null,
        ot_frequency: null,
        ot_minutes: null,
        ot_units: null,
        speech_frequency: null,
        speech_minutes: null,
        speech_units: null,
        dosage: null,
        strength: null,
        drug_route: null,
        is_mltc: null,
        process_date: null,
        auth_request_type: null,
      }));
    } else
      setFormData(f => ({
        ...f,
        [name]: value,
        level_one_reason_id: null,
        level_one_reason: null,
        insufficient_criteria_id: null,
        qualifies_for_level: null,
        rate_details: null,
        sub_level: null,
        service_id: null,
        service: null,
        is_therapy: null,
        pt: null,
        ot: null,
        speech: null,
        pt_frequency: null,
        pt_minutes: null,
        pt_units: null,
        ot_frequency: null,
        ot_minutes: null,
        ot_units: null,
        speech_frequency: null,
        speech_minutes: null,
        speech_units: null,
        dosage: null,
        strength: null,
        drug_route: null,
        process_date: null,
        auth_request_type: null,
      }));
    return;
  }

  if (name === 'authorization_status_id') {
    const end_date = new Date(`${formData.end_date} 00:00`);
    const start_date = new Date(`${formData.start_date} 00:00`);
    const isValidStart = isValid(start_date);
    const isValidEnd = isValid(end_date);
    setFormData({
      ...formData,
      authorization_status_id: value,
      days_approved:
        value !== DENIED && value !== DENIAL_RECEIVED
          ? isValidStart && isValidEnd
            ? differenceInCalendarDays(end_date, start_date) + 1
            : null
          : 0,
    });
    return;
  }
  if (name === 'start_date') {
    setSubLevelChoices([]);
    const { days_approved } = formData;
    const start_date = new Date(`${value} 00:00`);
    if (isValid(start_date) && days_approved) {
      const end_date = new Date(
        addDays(new Date(`${value} 00:00`), +days_approved - 1),
      );
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;
      setFormData({
        ...formData,
        start_date: value,
        end_date: format(end_date, 'yyyy-MM-dd'),
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
        authorization_type_id: null,
        level_one_reason_id: null,
        level_one_reason: null,
        insufficient_criteria_id: null,
        qualifies_for_level: null,
        rate_details: null,
        sub_level: null,
      });
    } else {
      setFormData({
        ...formData,
        start_date: value,
        authorization_type_id: null,
        level_one_reason_id: null,
        level_one_reason: null,
        insufficient_criteria_id: null,
        qualifies_for_level: null,
        rate_details: null,
        sub_level: null,
      });
    }
    return;
  }
  if (name === 'days_approved') {
    const { start_date: _start_date } = formData;
    const start_date = new Date(`${_start_date} 00:00`);
    const { authorization_status_id } = formData;
    if (value && isValid(start_date)) {
      const end_date = new Date(addDays(start_date, value - 1));
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;

      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? value
            : 0,
        end_date: format(end_date, 'yyyy-MM-dd'),
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
      });
    } else {
      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? value
            : 0,
      });
    }
    return;
  }
  if (name === 'end_date') {
    const { start_date: _start_date } = formData;
    const start_date = new Date(`${_start_date} 00:00`);
    const end_date = new Date(`${value} 00:00`);
    const { authorization_status_id } = formData;
    if (isValid(end_date) && isValid(start_date)) {
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;
      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? differenceInCalendarDays(end_date, start_date) + 1
            : 0,
        end_date: value,
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
      });
    } else {
      setFormData({ ...formData, end_date: value });
    }
    return;
  }

  if (name === 'initial_update_date') {
    setFormData(f => ({ ...f, [name]: value, update_weekday: [] }));
    return;
  }

  if (name === 'schedule_type') {
    const newState = { [name]: value };
    if (value === 'one_time' || value === 'recurring_day') {
      newState.update_frequency = null;
      newState.update_frequency_days = null;
      newState.no_update_reason = null;
    }
    if (value === 'one_time' || value === 'recurring_date') {
      newState.update_weekday = [];
      newState.no_update_reason = null;
    }
    if (value === 'recurring_day') {
      newState.initial_update_date = null;
      newState.no_update_reason = null;
    }
    if (value === 'no_update') {
      newState.update_weekday = [];
      newState.initial_update_date = null;
      newState.update_frequency = null;
      newState.update_frequency_days = null;
      newState.update_type = [];
      newState.update_notes = null;
    }
    setFormData(f => ({ ...f, ...newState }));
    return;
  }

  if (name === 'update_weekday') {
    if (Array.isArray(value) && value.length > 1) {
      setFormData(f => ({
        ...f,
        [name]: value,
        update_frequency: 'weekly',
      }));
      return;
    }
  }

  if (name === 'update_frequency') {
    if (value !== 'freq_days') {
      setFormData(f => ({
        ...f,
        [name]: value,
        update_frequency_days: null,
      }));
      return;
    }
  }

  if (
    name === 'ot_minutes' ||
    name === 'pt_minutes' ||
    name === 'speech_minutes'
  ) {
    const newValues = { ...formData, [name]: value };
    const property = name.split('_')[0];
    const unitValue = value ? parseInt(value) / 15 : null;
    newValues[`${property}_units`] = unitValue;
    setFormData({ ...newValues });
    return;
  }

  if (name === 'ot_units' || name === 'pt_units' || name === 'speech_units') {
    const newValues = { ...formData, [name]: value };
    const property = name.split('_')[0];
    const minutesValue = value ? parseInt(value) * 15 : null;
    newValues[`${property}_minutes`] = minutesValue;
    setFormData({ ...newValues });
    return;
  }
  if (name === 'is_retro') {
    setFormData({
      ...formData,
      [name]: value,
      is_primary: undefined,
      retro_close_date: null,
    });
    return;
  }
  if (name === 'cases_payers_id' || name === 'is_mltc') {
    setSubLevelChoices([]);
    setFormData(f => ({
      ...f,
      [name]: value,
      authorization_type_id: null,
      rate_details: null,
      level_one_reason_id: null,
      level_one_reason: null,
      insufficient_criteria_id: null,
      qualifies_for_level: null,
      sub_level: null,
    }));
    return;
  }

  if (name === 'service_id') {
    setFormData(f => ({
      ...f,
      [name]: value,
      pt_frequency: null,
      pt_minutes: null,
      pt_units: null,
      ot_frequency: null,
      ot_minutes: null,
      ot_units: null,
      speech_frequency: null,
      speech_minutes: null,
      speech_units: null,
    }));
    return;
  }
  if (name === 'is_therapy') {
    setFormData(f => ({
      ...f,
      [name]: value,
      service_id: null,
      service: null,
      pt: null,
      ot: null,
      speech: null,
      pt_frequency: null,
      pt_minutes: null,
      pt_units: null,
      ot_frequency: null,
      ot_minutes: null,
      ot_units: null,
      speech_frequency: null,
      speech_minutes: null,
      speech_units: null,
      dosage: null,
      strength: null,
      drug_route: null,
    }));
    return;
  }

  if (name === 'level_one_reason_id') {
    setFormData(f => ({
      ...f,
      [name]: value,
      insufficient_criteria_id: null,
      qualifies_for_level: null,
    }));
    return;
  }
  setFormData({ ...formData, [name]: value });
};

async function fetchResource(path, query = {}) {
  const response = await authGet([path, { ...query }]);
  if (response.error) return 'error';
  return response.data;
}

function getRecurringChoices(isRecurringDate) {
  const options = [
    { id: 'weekly', name: 'Weekly' },
    { id: 'biweekly', name: 'Biweekly' },
    { id: 'monthly', name: 'Monthly' },
  ];
  if (isRecurringDate) {
    options.push({ id: 'freq_days', name: 'Frequency of days' });
  }
  return options;
}

function setScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
  no_update_reason,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'recurring_day';
  } else if (update_frequency_days || update_frequency) {
    return 'recurring_date';
  } else if (initial_update_date) {
    return 'one_time';
  } else if (no_update_reason) {
    return 'no_update';
  } else {
    return '';
  }
}

function getEndDateValidators(values) {
  const validators = [
    validateDate(),
    minDate(values.start_date, 'End date can not be before the start date'),
  ];
  if (
    values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
    values.authorization_status_id !== AUTH_MISSING_ID &&
    values.authorization_status_id !== AUTH_ON_HOLD_ID &&
    values.authorization_type_id !== UPDATES_ONLY_ID &&
    values.authorization_type_id !== PART_B_AUTH_ID
  ) {
    validators.push(required());
  }
  return composeValidators(validators);
}
function validateAuthStatus(value, values) {
  if (value === 2 /** approved ended */) {
    const endDate = values.actual_end_date || values.end_date;
    if (!endDate || isBefore(new Date(endDate), new Date())) {
      return;
    }
    return 'Auths that have not yet ended cannot be set to Approved-Ended';
  }
}

function populateForm(
  record,
  setFormData,
  isEdit,
  roles,
  coveredUserId,
  userId,
) {
  const now = new Date();
  const end_date = new Date(`${record.end_date} 00:00`);
  const start_date = new Date(`${record.start_date} 00:00`);
  const isValidStart = isValid(start_date);
  const isValidEnd = isValid(end_date);
  const {
    id,
    insurance_auth_number,
    cases_payer,
    authorization_status,
    authorization_status_id,
    status,
    authorization_type,
    case: _case,
    created_at,
    deleted_at,
    updated_at,
    name,
    payer_name,
    update_type,
    update_weekday,
    update_frequency,
    inactive_schedule,
    created_by,
    updated_by,
    authorizationUpdatedBy,
    authorizationCreatedBy,
    outpatient_services: _outpatient_services,
    higher_level_requested,
    no_update,
    initial_update_date,
    update_frequency_days,
    no_update_reason,
    service_id,
    primary_diagnosis,
    billing_diagnosis,
    ...rest
  } = record;

  const outpatient_services = _outpatient_services;
  outpatient_services?.forEach(s => {
    const { frequency, minutes, service_id } = s;
    const therapyService = Object.entries(therapyServices).find(
      row => row[1] === service_id,
    );
    if (therapyService) {
      const name =
        therapyService[0] === 'SLP'
          ? 'speech'
          : therapyService[0].toLowerCase();
      rest[name] = true;
      rest[`${name}_frequency`] = frequency;
      rest[`${name}_minutes`] = minutes;
      rest[`${name}_units`] = minutes ? parseInt(minutes) / 15 : undefined;
      rest.is_therapy = 'true';
    } else {
      rest.is_therapy = 'false';
    }
  });

  if (isEdit) {
    setFormData(f => ({
      ...f,
      ...rest,
      days_approved:
        authorization_status_id !== DENIED &&
        authorization_status_id !== DENIAL_RECEIVED
          ? isValidStart && isValidEnd
            ? differenceInCalendarDays(end_date, start_date) + 1
            : null
          : 0,
      days_available:
        isValidEnd && isBefore(now, end_date)
          ? differenceInCalendarDays(end_date, now)
          : 0,
      authorization_id: id,
      update_type: inactive_schedule
        ? []
        : Array.isArray(update_type)
        ? update_type
        : [],
      update_weekday: inactive_schedule
        ? []
        : Array.isArray(update_weekday)
        ? update_weekday
        : [],
      schedule_type: inactive_schedule ? 'no_update' : setScheduleType(record),
      no_update_reason: inactive_schedule
        ? 'Disabled schedule'
        : no_update_reason,
      update_frequency: inactive_schedule
        ? null
        : update_frequency
        ? update_frequency
        : record.update_frequency_days
        ? 'freq_days'
        : undefined,
      initial_update_date: inactive_schedule ? null : initial_update_date,
      update_frequency_days: inactive_schedule ? null : update_frequency_days,
      authorization_status: authorization_status,
      authorization_status_id: authorization_status_id,
      higher_level_requested: higher_level_requested ? 'true' : 'false',
      service_id: service_id,
      dosage: _outpatient_services ? _outpatient_services[0]?.dosage : null,
      strength: _outpatient_services ? _outpatient_services[0]?.strength : null,
      drug_route: _outpatient_services
        ? _outpatient_services[0]?.drug_route
        : null,
      primary_diagnosis_value: primary_diagnosis,
      billing_diagnosis_value: billing_diagnosis,
    }));
  } else {
    //populate some fields from previous auth when creating new auth from denial
    setFormData(f => ({
      ...f,
      ...rest,
      start_date:
        end_date && isValid(end_date)
          ? format(new Date(addDays(end_date, 1)), 'yyyy-MM-dd')
          : undefined,
      end_date: undefined,
    }));
  }
}

function getOutpatientServicesValues(values) {
  if (values.authorization_type_id !== PART_B_AUTH_ID) return;
  const arr = [];
  // eslint-disable-next-line default-case
  if (values.pt) {
    arr.push({
      service_id: therapyServices.PT,
      frequency: values.pt_frequency,
      minutes: values.pt_minutes,
    });
  }
  if (values.ot) {
    arr.push({
      service_id: therapyServices.OT,
      frequency: values.ot_frequency,
      minutes: values.ot_minutes,
    });
  }
  if (values.speech) {
    arr.push({
      service_id: therapyServices.SLP,
      frequency: values.speech_frequency,
      minutes: values.speech_minutes,
    });
  }
  return arr.length && arr;
}
const getSubLevelChoices = async (
  setSubLevelChoices,
  cases_payers_id,
  level,
  start_date,
  payer_id,
  line_of_business_id,
) => {
  const response = await authGet([
    '/sub-levels/list',
    {
      cases_payers_id: cases_payers_id,
      payer_id: payer_id,
      line_of_business_id: line_of_business_id,
      level: level,
      start_date: start_date,
    },
  ]);
  if (!response.error) {
    setSubLevelChoices(response.data);
  }
};

export function MinuteUnitToggle({ setState, curValue }) {
  const handleChange = useCallback(
    e => {
      setState(e.target.value);
    },
    [setState],
  );

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>Minutes/Units</FormLabel>
      <RadioGroup value={curValue} onChange={handleChange} row>
        <FormControlLabel value='minutes' control={<Radio />} label='Minutes' />
        <FormControlLabel value='units' control={<Radio />} label='Units' />
      </RadioGroup>
    </FormControl>
  );
}
const drugRouteList = [
  { id: 'iv', name: 'IV' },
  { id: 'oral', name: 'Oral' },
  { id: 'peg tube', name: 'PEG tube' },
  { id: 'topical', name: 'Topical' },
  { id: 'im', name: 'IM' },
  { id: 'id', name: 'ID' },
  { id: 'sq', name: 'SQ' },
];
