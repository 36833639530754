import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import {
  TextField,
  SimpleShowLayout,
  // Filter,
  // SelectInput,
  FunctionField,
  BooleanField,
  // NumberField,
  // useRefresh,
} from 'react-admin';
import { Typography, Divider } from '@material-ui/core';
import { NotesModal } from '../../components/common/modals';
import { normalizePhone } from '../../components/common/modals/modalForms';
import { limitStringSize } from '../../utils';
import { useStyles } from './facility.styles';

export const GeneralInfo = ({ ...props }) => {
  const classes = useStyles();
  const { record = {} } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 470;

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          paddingBottom: 20,
          paddingTop: 20,
        }}
      >
        <div>
          <SimpleShowLayout {...props}>
            <TextField source='name' label='' className={classes.title} />
          </SimpleShowLayout>
          <SimpleShowLayout className={classes.twoColumnLayout} {...props}>
            <FunctionField
              source='legal_name'
              label='NOMNC facility name'
              render={record => {
                const { name, legal_name } = record;
                return legal_name || name;
              }}
            />
            <TextField
              source='corporate_llc_name'
              label='Facility legal name'
            />
          </SimpleShowLayout>
          <SimpleShowLayout {...props}>
            <FunctionField
              source='address'
              label=''
              render={record => {
                const { address, city, state, zip } = record;
                return (
                  <div>
                    {address}
                    <br />
                    {city + ', ' + state + ' ' + zip}
                  </div>
                );
              }}
            />{' '}
            <FunctionField
              source='phone'
              label=''
              render={record => {
                const ext = record.phone_ext ? `X${record.phone_ext}` : '';
                return record.phone
                  ? `${normalizePhone(record.phone)} ${ext}`
                  : null;
              }}
            />
          </SimpleShowLayout>

          <SimpleShowLayout className={classes.twoColumnLayout} {...props}>
            <FunctionField
              source='fax'
              label='Case management fax'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                return record.fax ? `${normalizePhone(record.fax)}` : null;
              }}
            />
            <FunctionField
              source='FacilitiesMainContact.phone'
              label='Main contact phone'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                if (!record.FacilitiesMainContact) return null;
                const {
                  FacilitiesMainContact: { phone, phone_ext } = {},
                } = record;
                const ext = phone_ext ? `x${phone_ext}` : '';
                return phone ? `${normalizePhone(phone)} ${ext}` : null;
              }}
            />
            <FunctionField
              source='FacilityGroups.name'
              label='Facility grouping'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                if (!record.FacilityGroups) return null;
                const { FacilityGroups: { name } = {} } = record;
                return name;
              }}
            />
            <TextField
              source='FacilitiesMainContact.email'
              label='Main contact email'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
            />
            <FunctionField
              source='client_group'
              label='Client group'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { client_group } = record;

                return client_group
                  ? client_group
                      .split('-')
                      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                      .join('-')
                  : '';
              }}
            />
            <TextField
              source='billing_group'
              label='Billing group'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
            />
          </SimpleShowLayout>
        </div>
        <Divider orientation='vertical' variant='middle' flexItem />
        <div style={{ display: 'block', minWidth: '60%', maxWidth: '60%' }}>
          <SimpleShowLayout
            className={clsx(classes.showLayout, classes.padding50)}
            {...props}
          >
            <FunctionField
              label='Contracted for MLTC'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { active_case_types } = record;
                return active_case_types !== 'subacute' ? 'Yes' : 'No';
              }}
            />
            {record.active_case_types === 'subacute' && (
              <FunctionField
                source='in_network'
                label=''
                className={clsx(classes.showItem)}
                render={record => <div />}
              />
            )}
            {record.active_case_types !== 'subacute' && (
              <FunctionField
                source=''
                label='MLTC case manager'
                className={clsx(classes.showItem, classes.email)}
                headerClassName={classes.showItemLabel}
                render={record => {
                  const { facilityMltcCm } = record;
                  const ext = facilityMltcCm?.extension
                    ? `X${facilityMltcCm.extension}`
                    : '';
                  return (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {facilityMltcCm?.name}
                      </span>
                      <br />
                      {facilityMltcCm?.phone
                        ? `${normalizePhone(facilityMltcCm.phone)} ${ext}`
                        : null}
                      <br />
                      {facilityMltcCm?.email}
                    </div>
                  );
                }}
              />
            )}
            {record.active_case_types !== 'subacute' && (
              <FunctionField
                source=''
                label='MLTC team lead'
                className={clsx(classes.showItem, classes.email)}
                headerClassName={classes.showItemLabel}
                render={record => {
                  const { facilityMltcCm } = record;
                  if (facilityMltcCm) {
                    const { TeamLeader } = facilityMltcCm;

                    const ext = TeamLeader?.extension
                      ? `X${TeamLeader.extension}`
                      : '';
                    return (
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {TeamLeader?.name}
                        </span>
                        <br />
                        {TeamLeader?.phone
                          ? `${normalizePhone(TeamLeader.phone)} ${ext}`
                          : null}
                        <br />
                        {TeamLeader?.email}
                      </div>
                    );
                  }
                }}
              />
            )}
          </SimpleShowLayout>
          <SimpleShowLayout
            className={clsx(classes.showLayout, classes.padding50)}
            {...props}
          >
            <FunctionField
              label='Contracted for subacute'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { active_case_types } = record;
                return active_case_types !== 'mltc' ? 'Yes' : 'No';
              }}
            />

            {record.active_case_types === 'mltc' && (
              <FunctionField
                source='in_network'
                label=''
                className={clsx(classes.showItem)}
                render={record => <div />}
              />
            )}
            {record.active_case_types !== 'mltc' && (
              <FunctionField
                source=''
                label='Subacute case manager'
                className={clsx(classes.showItem, classes.email)}
                headerClassName={classes.showItemLabel}
                render={record => {
                  const { facilitySubacuteCm } = record;
                  const ext = facilitySubacuteCm?.extension
                    ? `X${facilitySubacuteCm.extension}`
                    : '';
                  return (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        {facilitySubacuteCm?.name}
                      </span>
                      <br />
                      {facilitySubacuteCm?.phone
                        ? `${normalizePhone(facilitySubacuteCm.phone)} ${ext}`
                        : null}
                      <br />
                      {facilitySubacuteCm?.email}
                    </div>
                  );
                }}
              />
            )}
            {record.active_case_types !== 'mltc' && (
              <FunctionField
                source=''
                label='Subacute team lead'
                className={clsx(classes.showItem, classes.email)}
                headerClassName={classes.showItemLabel}
                render={record => {
                  const { facilitySubacuteCm } = record;
                  if (facilitySubacuteCm) {
                    const { TeamLeader } = facilitySubacuteCm;
                    const ext = TeamLeader?.extension
                      ? `X${TeamLeader.extension}`
                      : '';
                    return (
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {TeamLeader?.name}
                        </span>
                        <br />
                        {TeamLeader?.phone
                          ? `${normalizePhone(TeamLeader.phone)} ${ext}`
                          : null}
                        <br />
                        {TeamLeader?.email}
                      </div>
                    );
                  }
                }}
              />
            )}
          </SimpleShowLayout>
          <SimpleShowLayout
            className={clsx(classes.showLayout, classes.padding50)}
            {...props}
          >
            <FunctionField
              source=''
              label='Quality reviewer'
              className={clsx(classes.showItem, classes.email)}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { facilityQualityReviewer } = record;
                const ext = facilityQualityReviewer?.extension
                  ? `X${facilityQualityReviewer.extension}`
                  : '';
                return (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {facilityQualityReviewer?.name}
                    </span>
                    <br />
                    {facilityQualityReviewer?.phone
                      ? `${normalizePhone(
                          facilityQualityReviewer.phone,
                        )} ${ext}`
                      : null}
                    <br />
                    {facilityQualityReviewer?.email}
                  </div>
                );
              }}
            />
            <FunctionField
              source=''
              label='Appeal reviewer'
              className={clsx(classes.showItem, classes.email)}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { facilityAppealReviewer } = record;
                const ext = facilityAppealReviewer?.extension
                  ? `X${facilityAppealReviewer.extension}`
                  : '';
                return (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {facilityAppealReviewer?.name}
                    </span>
                    <br />
                    {facilityAppealReviewer?.phone
                      ? `${normalizePhone(facilityAppealReviewer.phone)} ${ext}`
                      : null}
                    <br />
                    {facilityAppealReviewer?.email}
                  </div>
                );
              }}
            />
          </SimpleShowLayout>
          <SimpleShowLayout {...props}>
            <FunctionField
              source='note'
              label='Notes'
              className={classes.showFullWidth}
              render={record => {
                const { note } = record;
                const truncated = limitStringSize(note, maxNotesLength, true);
                return note && note.length > maxNotesLength ? (
                  <span>
                    {truncated}
                    <Typography
                      color='primary'
                      component='span'
                      className={classes.showMore}
                      onClick={() => setShowNotesModal(true)}
                    >
                      Show more
                    </Typography>
                  </span>
                ) : (
                  truncated
                );
              }}
            />
            <TextField
              source='ur_meeting_notes'
              label='UR meeting'
              className={classes.showFullWidth}
            />
            <TextField
              source='coverage_notes'
              label='Coverage notes'
              className={classes.showFullWidth}
            />
          </SimpleShowLayout>
        </div>
      </div>
      <Divider />
      <SimpleShowLayout className={classes.showLayout}>
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.showFullWidth)}
          render={record => <div className={classes.title}>Details</div>}
        />
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.showLayout} {...props}>
        <TextField
          source='tax_id'
          label='Tax ID #'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='npi'
          label='NPI #'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='medicare_id'
          label='Medicare #'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='medicaid_id'
          label='Medicaid #'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='num_beds'
          label='Number of beds'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='emr_accurate'
          label='Is EMR accurate for payer source?'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <FunctionField
          source='high_cost_med'
          label='High cost medication alert'
          className={classes.showItem}
          render={record => {
            const updatedRecord = {
              ...record,
              high_cost_med: !!record.high_cost_med,
            };
            return (
              <BooleanField
                record={updatedRecord}
                source='high_cost_med'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                style={{
                  color: updatedRecord.high_cost_med ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='handles_appeals'
          label='MCO Manager handling appeals for HMO?'
          className={classes.showItem}
          render={record => {
            const updatedRecord = {
              ...record,
              handles_appeals: !!record.handles_appeals,
            };
            return (
              <BooleanField
                record={updatedRecord}
                source='handles_appeals'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                style={{
                  color: updatedRecord.handles_appeals ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='is_vent'
          label={
            <div>
              Vent facility? <br />
              <br />
            </div>
          }
          className={classes.showItem}
          render={record => {
            const updatedRecord = {
              ...record,
              is_vent: !!record.is_vent,
            };
            return (
              <BooleanField
                record={updatedRecord}
                source='is_vent'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                style={{
                  color: updatedRecord.is_vent ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='save_nri'
          label='Save NRI for new admissions?'
          className={classes.showItem}
          render={record => {
            const updatedRecord = {
              ...record,
              save_nri: !!record.save_nri,
            };
            return (
              <BooleanField
                record={updatedRecord}
                source='save_nri'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                style={{
                  color: updatedRecord.save_nri ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        {/* HACK for spacing */}
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.height1)}
          render={record => <div />}
        />
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.height1)}
          render={record => <div />}
        />
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.height1)}
          render={record => <div />}
        />{' '}
      </SimpleShowLayout>
      <Divider />
      <SimpleShowLayout className={classes.showLayout}>
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.showFullWidth)}
          render={record => <div className={classes.title}>Pharmacy info</div>}
        />
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.showLayout} {...props}>
        <TextField
          source='pharmacy.name'
          label='Pharmacy name'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='pharmacy.pharmacies_contact.name'
          label='Pharmacy contact'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='pharmacy.pharmacies_contact.email'
          label='Pharmacy contact email'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
      </SimpleShowLayout>
      <Divider />
      <SimpleShowLayout className={classes.showLayout}>
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.showFullWidth)}
          render={record => <div className={classes.title}>Physician info</div>}
        />
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.showLayout} {...props}>
        <TextField
          source='physician'
          label='Physician name'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='physician_address'
          label='Physician address'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='physician_tax_id'
          label='Physician tax ID'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        <TextField
          source='physician_npi'
          label='Physician NPI'
          className={classes.showItem}
          headerClassName={classes.showItemLabel}
        />
        {/* HACK for spacing */}
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.height1)}
          render={record => <div />}
        />
        <FunctionField
          source='in_network'
          label=''
          className={clsx(classes.showItem, classes.height1)}
          render={record => <div style={{ minWidth: '70vw' }} />}
        />

        {showNotesModal && (
          <NotesModal
            open={showNotesModal}
            handleClose={() => setShowNotesModal(false)}
            note={record.note}
          />
        )}
      </SimpleShowLayout>
    </Fragment>
  );
};
