import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  FormTextField,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
  minReasonLength,
} from '../modalForms';
import { SaveButton } from '../../../../design';
import { useStyles } from '../modal.styles';

export const EditPayerChange = ({
  caseId,
  record = {},
  handleClose,
  closeAside,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (record) {
      const { effective_date, reason } = record;
      setFormData(f => ({
        ...f,
        effective_date,
        reason,
      }));
    }
  }, [record]);

  const onSubmit = value => {
    return dataProvider
      .update('payer-change', {
        id: record.id,
        data: { ...value },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        handleClose();
        typeof refresh === 'function' && refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  name='effective_date'
                  label='Effective Date'
                  customOnChange={customOnChange}
                  type='date'
                  validate={composeValidators([required(), validateDate()])}
                  required
                />
                <FormTextField
                  name='reason'
                  label='Payer change reason'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={composeValidators([required(), minReasonLength()])}
                  required
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16, justifyContent: 'space-between' }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
