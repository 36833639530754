import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  email,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
  usePermissions,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  // ReferenceInput,
  FormTextField,
  // SelectComponent,
  // FileInput,
  composeValidators,
  minLength,
  handleNumbers,
  getDateInputValue,
  validateEmailOnSubmit,
  minReasonLength,
  SwitchInput,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const PayerRepForm = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  closeAside,
  payerFacilityId,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    rep_payer_facility_id: payerFacilityId,
    is_active: true,
  });
  useEffect(() => {
    if (isEdit && record) {
      const { id, ...rest } = record;
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const { email } = value;
    const data = {
      ...value,
      email: validateEmailOnSubmit(email),
    };

    if (isEdit) {
      return dataProvider
        .update('payers-facility/payer-reps', {
          id: record.id,
          data: { ...data },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('payers-facility/payer-reps', { data: { ...data } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='name'
                    validate={required()}
                    required
                    label='Insurance case manager name'
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone'
                    type='text'
                    format='phone'
                    validate={composeValidators([
                      required(),
                      minLength(10, 'Invalid phone number'),
                    ])}
                    required
                    label='Insurance case manager phone'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone_ext'
                    label='Ext.'
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='fax'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                    type='text'
                    format='phone'
                    label='Insurance case manager fax'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='email'
                    validate={composeValidators([email()])}
                    label='Insurance case manager email'
                    type='email'
                    style={{ marginRight: 0 }}
                  />
                </div>
                {(permissions.indexOf('admin') > -1 ||
                  permissions.indexOf('contract_user') > -1 ||
                  permissions.indexOf('supervisor') > -1) && (
                  <SwitchInput
                    name='is_active'
                    customOnChange={customOnChange}
                    label={formData.is_active ? 'Active' : 'Inactive'}
                    checked={formData.is_active}
                  />
                )}
                <FormTextField
                  name='comments'
                  label='Insurance case manager notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={minReasonLength()}
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
